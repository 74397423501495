<script>
import Base from '@backend/Base.vue';

import BOGen from '@helper/BOGen';

export default {
	name: "MrClient",
	extends: Base,
  data(){
    return {
		Name:"MrClient",
		mrLevel: {},
    }
  },
  mounted(){
    this.$set(this.$root, 'page', this)
	this.refreshData()
	},
  watch:{
    '$route.query'(){
		this.refreshData()
	},
    'filter.level'(v){
		if(!v) return
		this.search()
	},
  }
}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
             <div class="card">
                <div class="card-body">
	                <VForm @resp="search">
						<div class="row">
							<div class="col-sm-5">
								<h5 class="card-title">{{ObjectName}} List <span v-if="data.total">({{(data.total||0)}})</span></h5>
							</div>
							<div class="col-sm-3"></div>
							<div class="col-sm-3">
								<div class="form-group mb-0">
                                    <div class="input-group">
                                       <input type="text" v-model="filter.search" v-on:keyup.enter="search"  class="form-control" placeholder="Search...">
                                       <div class="input-group-append">
                                          <button class="btn btn-info" type="button" @click="search()"><i class="fas fa-search"></i></button>
                                       </div>
                                    </div>
                                 </div>
							</div>
							<div class="col-sm-1">
								<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
							</div>
						</div>
					</VForm>
                </div>
                <div class="table-responsive ajax-table">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<th>#</th>
								<th><SortField name="mc_name"></SortField></th>
								<th>{{fields.mc_join_date}}</th>
								<th>{{fields.mc_pic_name}}</th>
								<th>{{fields.mc_pic_position}}</th>
								<th>{{fields.mc_pic_contact}}</th>
								<th>{{fields.mc_status}}</th>
								<th width="120px">Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(v,k) in data.data" :key="k">
								<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{(v.mc_name||'-')}}<br><small>code : {{v.mc_code}}</small></td>
								<td>{{(v.mc_join_date||'').dates('format')}}</td>
								<td>{{(v.mc_pic_name||'-')}}</td>
								<td>{{(v.mc_pic_position||'-')}}</td>
								<td>{{('0'+v.mc_pic_contact||'-')}}</td>
								<td><StatusLabel type="status_management" :status="v.status"></StatusLabel></td>
								<td class="btn-action">
									<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i class="ti-marker-alt"></i></router-link>
									<a v-if="page.moduleRole('ChangeStatus')" href="javascript:;" class="icon_action" @click="changeStatusManagement(k)" v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
									<a v-if="page.moduleRole('Delete')" href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'"><i class="ti-trash"></i></a>
								</td>
							</tr>
							<tr v-if="NotFound">
								<td colspan="99">
									<h3 class="tc">{{NotFound}}</h3>
								</td>
							</tr>
							<tr v-if="data.data===false">
								<td colspan="99">
									<LoadingSpinner light></LoadingSpinner>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="panel-body">
					<div class="pull-right">
						<Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
             </div>
          </div>
       </div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
                      <div class="col-md-8">
                         <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                      </div>
                   </div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="mc_name" v-model="row.mc_name"></BoField>
							<BoField name="mc_pic_position" v-model="row.mc_pic_position"></BoField>
							<div class="row"><div class="col-md-4">
								<BoField name="mc_color" v-bind="validation('mc_color')" info="this is use for color indicator in dashboard" v-model="row.mc_color" :attr="{type:'color'}"></BoField>
							</div></div>
							<div class="row">	
								<div class="col-7">							
									<BoField name="mc_status">
										<div class="row">
											<radio name="mc_status" v-model="row.mc_status" option="E" :attr="validation('mc_status')">Enabled</radio>
											<radio name="mc_status" v-model="row.mc_status" option="D">Disabled</radio>
											<radio name="mc_status" v-model="row.mc_status" option="S">Suspended</radio>
										</div>
									</BoField>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<BoField name="mc_pic_name" v-model="row.mc_pic_name"></BoField>
							<BoField name="mc_pic_contact" v-model="row.mc_pic_contact" :attr="{type:'Number',min:0}"></BoField>
							<BoField name="mc_join_date">
								<DatePicker name="mc_join_date" placeholder="DD-MM-YYYY" v-model="row.mc_join_date" v-bind="validation('mc_join_date')"></DatePicker>
							</BoField>
						</div>
					</div>
					<hr>
					<div class="text-right">
						<router-link :to="{name:Name}" class="btn">Cancel</router-link>
						<button type="submit" class="btn btn-loading btn-success"><i class="icon-check"></i>Submit</button>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>
